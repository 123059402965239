@import "ng-uikit-pro-standard/assets/scss/core/colors";
@import "ng-uikit-pro-standard/assets/scss/core/variables";
@import "ng-uikit-pro-standard/assets/scss/core/variables-pro";
@import "ng-uikit-pro-standard/assets/scss/addons-pro/_ecommerce-pro.scss"; // Import only when you want to use the Products examples
@import "ng-uikit-pro-standard/assets/scss/sections-pro/_pricing-pro.scss"; // Import only when you want to use the Pricing examples

html,
body,
header,
#intro-section {
  height: 100vh !important;
}

@media (max-width: 812px) {
  html,
  body,
  header,
  #intro-section,
  video {
    min-height: 100vh;
  }
}

.view.mask {
  overflow: auto !important;
}

.view {
    height: 100h !important;
}

.top-nav-collapse {
  background-color: #114861 !important;
}

@media (max-width: 768px) {
    .navbar:not(.top-nav-collapse) {
        background: #114861 !important;
    }
}

.rgba-gradient .full-bg-img {
    background: linear-gradient(45deg, rgba(242, 34, 50, 0.5), rgba(17, 72, 97, 0.6) 100%);
}

.produtos .controls-top .btn-floating {
  background: #f8a60d;
}

.app-heading {
  font-size: 3.5rem;
  font-weight: bold;
}

.page-footer {
  background: #114861 !important;
  .page-footersub {
    background: #269dd4 !important;
  }
}

.text-color-a {
  color: #1F6503 !important;
}

.text-color-b {
  color: #E8CB0D !important;
}

.text-color-c {
  color: #0f29ac !important;
}


.sombra {
    text-shadow: 0.03em 0.03em 0.15em #8b8b8b;
}
  
.sombra-title {
    text-shadow: 0.03em 0.03em 0.15em #000;
}

/* Sprite */
i.flag:not(.icon):before {
  display: inline-block;
  content: '';
  background: url("https://mdbootstrap.com/img/svg/flags.png") no-repeat -108px -1976px;
  width: 16px;
  height: 11px;
}

i.flag.br:before,
i.flag.brazil:before {
  background-position: 0px -728px;
}